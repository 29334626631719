import axios from "axios";
import * as Configs from "../config";
import { errorHandling, setHeader } from "./api";

export class RegistrationStore {
  async getRegistration(address: string) {
    const url = `${Configs.API_URL}/event/registration?address=${address}&type=genesis_membership`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["msg"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async register(payload: any) {
    const url = `${Configs.API_URL}/event/register`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["msg"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_REGISTRATION = "registrationStore";
