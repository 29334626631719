import { Container, MoonAnimation } from "../components";
import { useEffect, useState } from "react";
import { useRegistrationStore } from "src/stores";
import Web3 from "web3";

export const Home = (props: any) => {
  const [step, setStep] = useState(100);
  const [address, setAddress] = useState(null);

  const { getRegistration, register } = useRegistrationStore();

  useEffect(() => {
    if (step >= 105) return;
    let time = 10000;
    switch (step) {
      case 100:
        time = 1000;
        break;
      case 103:
        time = 15000;
        break;
      default:
        time = 10000;
        break;
    }
    setTimeout(() => {
      setStep(step + 1);
    }, time);
  }, [step]);

  useEffect(() => {
    if (!address) return;
    getRegistration(address).then((result) => {
      if (result !== "event_can_register") {
        return setStep(106);
      }

      register({
        address: address,
        type: "genesis_membership",
      }).then((msg) => {
        return setStep(106);
      });
    });
  }, [address, getRegistration, register]);

  async function connect() {
    // @ts-ignore: Unreachable code error
    const web3 = new Web3(window?.lukso);
    await web3.eth.requestAccounts();
    try {
      const accounts: any = await web3.eth.requestAccounts();
      console.log("Account connected:", accounts);
      setAddress(accounts[0]);
    } catch (error) {
      // handle connection error
      console.log("Error in connecting account:", error);
    }
  }

  console.log("step", step);
  return (
    <Container>
      <div
        className="home py-[120px] px-[30px] h-full bg-center bg-cover overflow-hidden"
        style={{ backgroundImage: "url('/assets/game-asset-bg.jpg')" }}
      >
        <div className="flex flex-col justify-center items-center h-full relative z-[5] text-center">
          <h1
            className={`${$txtStyle} ${
              step === 101 ? "w-[100%]" : "w-[0px] h-[0px]"
            }`}
          >
            Welcome to N3 SNAP HUB
          </h1>

          <h1
            className={`${$txtStyle} ${
              step === 102 ? "w-[100%]" : "w-[0px] h-[0px]"
            }`}
          >
            Are you ready to get the Genesis Membership
          </h1>

          <div
            className={`relative z-[5] top-[0px] rounded-full w-[256px] md:w-[512px] overflow-hidden transition-all duration-[2000ms]  ${
              step === 103
                ? "visible opacity-[100]"
                : "invisible h-[0px] opacity-[0]"
            }`}
          >
            <video
              width="320"
              height="240"
              autoPlay={true}
              muted
              controls={false}
              loop={true}
            >
              <source src="/assets/n3snap.mp4" type="video/mp4" />
            </video>
          </div>

          <h1
            className={`${$txtStyle} ${
              step === 104 ? "w-[100%]" : "w-[0px] h-[0px]"
            } !top-[0px]`}
          >
            LSP7 & LSP8 NFTs
          </h1>

          <h1
            className={`${$txtStyle} ${
              step === 105 ? "w-[100%]" : "w-[0px] h-[0px]"
            } !top-[0px]`}
          >
            Connect your <span className="txt-gradient">UP</span> to Register
          </h1>

          <div
            className={`top-[20px] relative z-[5] overflow-hidden transition-all duration-[2000ms]  ${
              step === 105 ? "w-[100%]" : "w-[0px] h-[0px]"
            }`}
          >
            <button
              className="gradient font-[400] text-[10px] md:text-[12px] whitespace-nowrap"
              onClick={() => connect()}
            >
              Register for the Waitlist
            </button>
          </div>

          <h1
            className={`${$txtStyle} ${
              step === 106 ? "w-[100%]" : "w-[0px] h-[0px]"
            } !top-[0px]`}
          >
            You have successfully registered!
          </h1>

          <div className="absolute z-[1]">
            <MoonAnimation />
          </div>
        </div>
      </div>
    </Container>
  );
};

const $txtStyle =
  "text-[12px] leading-[12px] md:text-[16px] md:leading-[16px] font-[400] z-[5] transition-all duration-[2000ms] whitespace-nowrap relative bg-clip-text overflow-hidden top-[20px]";
