// ./src/stores/index.ts
import { createContext, useContext } from "react";
import { STORE_ROUTER, RouterStore, history } from "./router";
import { RegistrationStore, STORE_REGISTRATION } from "./registration";

function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_REGISTRATION]: new RegistrationStore(),
  };
}

export const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

export function useRegistrationStore() {
  const { registrationStore } = useStores();
  return registrationStore;
}

export { history };

export const generalError = { success: false, error: "general_error" };
